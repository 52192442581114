import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';

import {
  GET_CHAPTER,
  CLEAR_CHAPTER,
  PUT_CHAPTER
} from './constants';

const initialState = {
  ...defaultFetchDataState,
  updating: false
};

const chapter = createReducer(initialState, {
  [GET_CHAPTER]: (state, action) => defaultFetchReducer(state, action, {
    FAIL: () => ({
      ...state,
      fetching: false,
      errors: action.request.response.data.error
    })
  }),

  [PUT_CHAPTER]: (state, action) => defaultFetchReducer(state, action, {
    SEND: () => ({
      ...state,
      updating: true
    }),

    SUCCESS: () => ({
      ...state,
      updating: false,
      data: action.request.response.data,
      errors: null
    }),

    FAIL: () => ({
      ...state,
      updating: false,
      errors: action.request.response.data
    })
  }),

  [CLEAR_CHAPTER]: (state) => ({
    ...state,
    ...defaultFetchDataState
  })
});

export default chapter;
