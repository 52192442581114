import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import { POST_LOGOUT_TOKEN } from '../auth/constants';
import { GET_TAX } from './constants';

const tax = createReducer(defaultFetchDataState, {
  [GET_TAX]: (state, action) => defaultFetchReducer(state, action, {
    SUCCESS: () => {
      const { data } = action.request.response;

      return {
        ...state,
        fetching: false,
        data: data ? data.tax : null,
        errors: null
      };
    }
  }),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

export default tax;
