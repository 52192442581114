import { localStorage } from '../../utils';
import {
  SET_I18N,
  TOGGLE_LINKS_VISIBLE,
  OPEN_NAVIGATION_SIDEBAR,
  CLOSE_NAVIGATION_SIDEBAR,
  TOGGLE_NAVIGATION_SIDEBAR,
  SET_NAVIGATION_SIDEBAR,
  SET_COOKIE_CONSENT
} from './constants';

const COOKIE_CONSENT_KEY = 'cookieConsent';

export const setCookieConsent = () => {
  localStorage.setItem(COOKIE_CONSENT_KEY, String(true));

  return { type: SET_COOKIE_CONSENT };
};

export const checkCookieConsent = () => (dispatch) => {
  const consent = localStorage.getItem(COOKIE_CONSENT_KEY);

  if (consent === String(true)) {
    dispatch(setCookieConsent());
  }
};

export const setI18n = (i18n) => ({
  type: SET_I18N,
  i18n
});

export const toggleLinksVisible = () => ({
  type: TOGGLE_LINKS_VISIBLE
});

export const openNavigationSidebar = () => ({
  type: OPEN_NAVIGATION_SIDEBAR
});

export const closeNavigationSidebar = () => ({
  type: CLOSE_NAVIGATION_SIDEBAR
});

export const toggleNavigationSidebar = () => ({
  type: TOGGLE_NAVIGATION_SIDEBAR
});

export const setNavigationSidebar = (open) => ({
  type: SET_NAVIGATION_SIDEBAR,
  open
});
