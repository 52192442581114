import { localStorage } from '../../utils';
import {
  isSuccessAction,
  createActionRequestWithAuth
} from '../utils';
import {
  SET_TOKEN,
  DELETE_TOKEN,
  ADD_DEFERRED_ACTION,
  CLEAR_DEFERRED_ACTIONS,
  POST_REFRESH_TOKEN,
  POST_LOGIN,
  POST_LOGOUT_TOKEN
} from './constants';
import {
  tokenSelector,
  refreshTokenSelector,
  isRefreshingSelector,
  deferredActionsSelector
} from './selectors';

import { getPlans } from '../plans/actions';
import { i18nPush } from '../../utils/router';

const AUTH_TOKEN_KEY = 'auth_token';
const AUTH_REFRESH_TOKEN_KEY = 'auth_refresh_token';

export const saveTokenToLS = (token, refreshToken) => {
  localStorage.setItem(AUTH_TOKEN_KEY, token);
  localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, refreshToken);
};

const deleteTokenFromLS = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
};

export const setToken = (token, refreshToken) => ({
  type: SET_TOKEN,
  token,
  refreshToken
});

export const deleteToken = () => {
  deleteTokenFromLS();

  return {
    type: DELETE_TOKEN
  };
};

export const checkToken = () => (dispatch) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const refreshToken = localStorage.getItem(AUTH_REFRESH_TOKEN_KEY);

  if (token && refreshToken) {
    dispatch(setToken(token, refreshToken));
  }
};

export const login = (credentials) => (dispatch, getState) => dispatch({
  authorization: true,
  type: POST_LOGIN,
  request: {
    method: 'POST',
    endpoint: 'token/',
    data: credentials
  }
}).then((action) => {
  if (isSuccessAction(action)) {
    const state = getState();
    const token = tokenSelector(state);
    const refreshToken = refreshTokenSelector(state);
    saveTokenToLS(token, refreshToken);
  }

  return action;
});

export const refresh = () => (dispatch, getState) => {
  const refreshToken = refreshTokenSelector(getState());

  return dispatch(createActionRequestWithAuth({
    type: POST_REFRESH_TOKEN,
    request: {
      method: 'POST',
      endpoint: 'token/refresh/',
      data: { refresh: refreshToken }
    }
  })).then((action) => {
    if (isSuccessAction(action)) {
      const state = getState();
      const token = tokenSelector(state);
      const newRefreshToken = refreshTokenSelector(state);
      saveTokenToLS(token, newRefreshToken);
    }

    return action;
  });
};

export const logout = () => (dispatch, getState) => {
  const refreshToken = refreshTokenSelector(getState());

  return dispatch(createActionRequestWithAuth({
    type: POST_LOGOUT_TOKEN,
    request: {
      method: 'POST',
      endpoint: 'token/logout/',
      data: { refreshToken }
    }
  })).then((action) => {
    dispatch(deleteToken());
    dispatch(getPlans()); // TODO:
    i18nPush('/login/');

    return action;
  });
};

export const addDeferredAction = (action) => ({
  type: ADD_DEFERRED_ACTION,
  action
});

export const clearDeferredActions = () => ({
  type: CLEAR_DEFERRED_ACTIONS
});

export const onUnauthorized = (deferredAction) => (dispatch, getState) => {
  dispatch(addDeferredAction(deferredAction));

  const refreshing = isRefreshingSelector(getState());

  if (refreshing) {
    return Promise.resolve();
  }

  return dispatch(refresh()).then((action) => {
    if (!isSuccessAction(action)) {
      dispatch(deleteToken());
      i18nPush('/login/');
      return action;
    }

    const deferredActions = deferredActionsSelector(getState());
    const promises = deferredActions.map((a) => dispatch(createActionRequestWithAuth(a)));

    dispatch(clearDeferredActions());

    return Promise.all(promises);
  });
};
