import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';

import { GET_BADGES } from './constants';

const badges = createReducer(defaultFetchDataState, {
  [GET_BADGES]: defaultFetchReducer
});

export default badges;
