import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer, defaultResetStateReducer
} from '../utils';

import { POST_LOGOUT_TOKEN } from '../auth/constants';
import { CLEAR_MODULES, GET_MODULES } from './constants';

const modules = createReducer(defaultFetchDataState, {
  [GET_MODULES]: defaultFetchReducer,

  [CLEAR_MODULES]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

export default modules;
