import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';
import { GET_JOB_POSITIONS } from './constants';

const initialState = { ...defaultFetchDataState };

const jobPositions = createReducer(initialState, {
  [GET_JOB_POSITIONS]: defaultFetchReducer
});

export default jobPositions;
