import { createSelector } from 'reselect';

import { defaultErrorsSelector } from '../utils';

const slice = ({ auth }) => auth;

export const tokenSelector = createSelector(
  slice,
  ({ token }) => token || null
);

export const refreshTokenSelector = createSelector(
  slice,
  ({ refreshToken }) => refreshToken
);

export const tokenDataSelector = createSelector(
  slice,
  ({ tokenData }) => tokenData || null
);

export const userIdSelector = createSelector(
  tokenDataSelector,
  (tokenData) => tokenData && tokenData.user_id
);

export const isAuthorizingSelector = createSelector(
  slice,
  ({ isAuthorizing }) => isAuthorizing
);

export const isAuthorizedSelector = createSelector(
  slice,
  ({ isAuthorized }) => isAuthorized
);

export const isRefreshingSelector = createSelector(
  slice,
  ({ isRefreshing }) => isRefreshing
);

export const deferredActionsSelector = createSelector(
  slice,
  ({ deferredActions }) => deferredActions
);

export const errorsSelector = defaultErrorsSelector(slice);
