import { createSelector } from 'reselect';

import { localStorage } from '../utils';

export const createReducer = (initialState, reducersMap = {}) => (state = initialState, action = {}) => {
  const reducer = reducersMap[action.type];
  return reducer ? reducer(state, action) : state;
};

export const createRequestReducer = (state, action, reducerMap) => {
  const reducer = reducerMap[action.status];
  return reducer ? reducer(state, action) : state;
};

export const createActionRequestWithAuth = (action) => {
  const { request } = action;
  const token = localStorage.getItem('auth_token');

  if (!request || !token) {
    return action;
  }

  return {
    ...action,
    request: {
      ...request,
      headers: {
        ...(request.headers || {}),
        Authorization: `Bearer ${token}`
      }
    }
  };
};

export const isSuccessAction = (action) => (!!action && action.status === 'SUCCESS');

export const isFailAction = (action) => (!!action && action.status === 'FAIL');

export const defaultFetchDataState = {
  data: null,
  fetching: false,
  errors: null
};

export const defaultFetchReducer = (state, action, reducerMap = {}) => createRequestReducer(state, action, {
  SEND: () => ({
    ...state,
    fetching: true
  }),

  SUCCESS: () => ({
    ...state,
    fetching: false,
    data: action.request.response.data,
    errors: null
  }),

  FAIL: () => ({
    ...state,
    fetching: false,
    errors: action.request.response.data
  }),

  ...reducerMap
});

export const defaultResetStateReducer = (initialState) => (state) => ({
  ...state,
  ...initialState
});

export const defaultFetchingSelector = (slice, defaultValue = false) => createSelector(
  slice,
  ({ fetching }) => fetching || defaultValue
);

export const defaultUpdatingSelector = (slice, defaultValue = false) => createSelector(
  slice,
  ({ updating }) => updating || defaultValue
);

export const defaultDataSelector = (slice, defaultValue = null) => createSelector(
  slice,
  ({ data }) => data || defaultValue
);

export const defaultErrorsSelector = (slice, defaultValue = null) => createSelector(
  slice,
  ({ errors }) => errors || defaultValue
);
