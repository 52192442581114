import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';

import { GET_COUNTRIES } from './constants';

const countries = createReducer(defaultFetchDataState, {
  [GET_COUNTRIES]: defaultFetchReducer
});

export default countries;
