import { createActionRequestWithAuth } from '../utils';
import { CLEAR_PLANS, GET_PLANS } from './constants';

export const getPlans = () => createActionRequestWithAuth({
  type: GET_PLANS,
  request: {
    method: 'GET',
    endpoint: 'plans/'
  }
});

export const clearPlans = () => ({
  type: CLEAR_PLANS
});
