import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import {
  CLEAR_CONTRIBUTOR,
  GET_CONTRIBUTOR
} from './constants';

const contributor = createReducer(defaultFetchDataState, {
  [GET_CONTRIBUTOR]: defaultFetchReducer,

  [CLEAR_CONTRIBUTOR]: defaultResetStateReducer(defaultFetchDataState)
});

export default contributor;
