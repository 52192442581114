import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';

import {
  GET_CASE_STUDY,
  CLEAR_CASE_STUDY,
  PUT_CASE_STUDY
} from './constants';

const initialState = {
  ...defaultFetchDataState,
  updating: false
};

const caseStudy = createReducer(initialState, {
  [GET_CASE_STUDY]: (state, action) => defaultFetchReducer(state, action, {
    FAIL: () => ({
      ...state,
      fetching: false,
      errors: action.request.response.data.error
    })
  }),

  [PUT_CASE_STUDY]: (state, action) => defaultFetchReducer(state, action, {
    SEND: () => ({
      ...state,
      updating: true
    }),

    SUCCESS: () => ({
      ...state,
      updating: false,
      data: action.request.response.data,
      errors: null
    }),

    FAIL: () => ({
      ...state,
      updating: false,
      errors: action.request.response.data
    })
  }),

  [CLEAR_CASE_STUDY]: (state) => ({
    ...state,
    ...defaultFetchDataState
  })
});

export default caseStudy;
