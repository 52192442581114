import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import {
  POST_LOGOUT_TOKEN
} from '../auth/constants';
import {
  POST_COUPON_CODES_VALIDATE,
  CLEAR_COUPON_CODES
} from './constants';

const initialState = {
  ...defaultFetchDataState
};

const couponCodes = createReducer(initialState, {
  [POST_COUPON_CODES_VALIDATE]: (state, action) => defaultFetchReducer(state, action, {
    SEND: () => ({
      ...state,
      data: null,
      fetching: true
    })
  }),

  [CLEAR_COUPON_CODES]: defaultResetStateReducer(initialState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(initialState)
});

export default couponCodes;
