import {
  createReducer,
  createRequestReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';

import {
  GET_SESSIONS,
  POST_SESSION_TERMINATE
} from './constants';

const initialState = {
  ...defaultFetchDataState
};

const sessions = createReducer(initialState, {
  [GET_SESSIONS]: defaultFetchReducer,

  [POST_SESSION_TERMINATE]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: state.data ? state.data.filter((session) => session.isCurrent) : null
    })
  })
});

export default sessions;
