import {
  createReducer,
  createRequestReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import { POST_LOGOUT_TOKEN } from '../auth/constants';
import { GET_ORGANIZATION, PATCH_ORGANIZATION } from './constants';
import { DELETE_INVITE, POST_INVITE } from '../invite/constants';

const initialState = {
  ...defaultFetchDataState,
  updating: false
};

const organization = createReducer(initialState, {
  [GET_ORGANIZATION]: defaultFetchReducer,

  [PATCH_ORGANIZATION]: (state, action) => createRequestReducer(state, action, {
    SEND: () => ({
      ...state,
      updating: true
    }),

    SUCCESS: () => ({
      ...state,
      updating: false,
      data: {
        ...state.data,
        ...action.request.response.data
      },
      errors: null
    }),

    FAIL: () => ({
      ...state,
      updating: false,
      errors: action.request.response.data
    })
  }),

  [POST_INVITE]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...(state.data || {}),
        invites: [
          ...(state.data ? state.data.invites || [] : []),
          action.request.response.data
        ]
      }
    })
  }),

  [DELETE_INVITE]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => {
      if (!Array.isArray(state.data ? state.data.invites : null)) {
        return state;
      }

      const index = state.data.invites.findIndex(({ id }) => id === action.id);

      if (index === -1) {
        return state;
      }

      const invites = [...state.data.invites];
      invites.splice(index, 1);

      return {
        ...state,
        data: {
          ...state.data,
          invites
        }
      };
    }
  }),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(initialState)
});

export default organization;
