import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';

import { GET_PARTNERS } from './constants';

const initialState = {
  ...defaultFetchDataState
};

const partners = createReducer(initialState, {
  [GET_PARTNERS]: defaultFetchReducer
});

export default partners;
