export const GET_RESOURCES_FILES = 'resources/GET_RESOURCES_FILES';
export const CLEAR_RESOURCES_FILES = 'resources/CLEAR_RESOURCES_FILES';

export const GET_VIDEO_FILES = 'resources/GET_VIDEO_FILES';
export const CLEAR_VIDEO_FILES = 'resources/CLEAR_VIDEO_FILES';

export const GET_VIDEO_CATEGORIES = 'resources/GET_VIDEO_CATEGORIES';
export const CLEAR_VIDEO_CATEGORIES = 'resources/CLEAR_VIDEO_CATEGORIES';

export const GET_TEMPLATES = 'resources/GET_TEMPLATES';
export const CLEAR_TEMPLATES = 'resources/CLEAR_TEMPLATES';
