import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import config from '../config';

import createApiMiddleware from './middlewares/createApiMiddleware';
import createAuthMiddleware from './middlewares/createAuthMiddleware';

import { onUnauthorized } from './auth/actions';

import account from './account/reducer';
import app from './app/reducer';
import auth from './auth/reducer';
import badges from './badges/reducer';
import book from './book/reducer';
import bookTranslation from './bookTranslation/reducer';
import caseStudies from './caseStudies/reducer';
import caseStudy from './caseStudy/reducer';
import caseStudyTranslation from './caseStudyTranslation/reducer';
import chapter from './chapter/reducer';
import chapterTranslation from './chapterTranslation/reducer';
import chapters from './chapters/reducer';
import contactSales from './contactSales/reducer';
import contributor from './contributor/reducer';
import contributors from './contributors/reducer';
import countries from './countries/reducer';
import couponCodes from './couponCodes/reducer';
import editor from './editor/reducer';
import events from './events/reducer';
import markup from './markup/reducer';
import invite from './invite/reducer';
import jobPositions from './jobPositions/reducer';
import module from './module/reducer';
import modules from './modules/reducer';
import notifications from './notifications/reducer';
import organization from './organization/reducer';
import partners from './partners/reducer';
import plans from './plans/reducer';
import posters from './posters/reducer';
import registration from './registration/reducer';
import resetPassword from './resetPassword/reducer';
import resources from './resources/reducer';
import search from './search/reducer';
import sections from './sections/reducer';
import sessions from './sessions/reducer';
import socialMedia from './socialMedia/reducer';
import subscription from './subscription/reducer';
import subscriptions from './subscriptions/reducer';
import tax from './tax/reducer';
import updatePassword from './updatePassword/reducer';
import vat from './vat/reducer';

const rootReducer = combineReducers({
  account,
  app,
  auth,
  badges,
  book,
  bookTranslation,
  caseStudies,
  caseStudy,
  caseStudyTranslation,
  chapter,
  chapterTranslation,
  chapters,
  contactSales,
  contributor,
  contributors,
  countries,
  couponCodes,
  module,
  modules,
  editor,
  events,
  markup,
  invite,
  jobPositions,
  notifications,
  organization,
  partners,
  plans,
  posters,
  resources,
  registration,
  resetPassword,
  search,
  sections,
  sessions,
  socialMedia,
  subscription,
  subscriptions,
  tax,
  updatePassword,
  vat
});

function configureStore(history, initialState) {
  const middlewares = [
    thunkMiddleware,
    createApiMiddleware({
      protocol: config.API_PROTOCOL,
      host: config.API_HOST,
      apiPath: config.API_PATH
    }),
    createAuthMiddleware(onUnauthorized)
  ];

  const composeEnhancers = composeWithDevTools({ name: 'KMM-WEBAPP' });
  const enhancers = composeEnhancers(applyMiddleware(...middlewares));

  return createStore(
    rootReducer,
    initialState,
    enhancers
  );
}

export default configureStore;
