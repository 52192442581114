import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';

import { POST_SUBSCRIPTIONS_REQUESTS } from './constants';

const initialState = {
  ...defaultFetchDataState
};

const contactSales = createReducer(initialState, {
  [POST_SUBSCRIPTIONS_REQUESTS]: defaultFetchReducer
});

export default contactSales;
