import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import { POST_LOGOUT_TOKEN } from '../auth/constants';
import {
  GET_SEARCH,
  CLEAR_SEARCH
} from './constants';

const search = createReducer(defaultFetchDataState, {
  [GET_SEARCH]: (state, action) => defaultFetchReducer(state, action, {
    SEND: () => ({
      ...state,
      data: defaultFetchDataState.data,
      fetching: true
    })
  }),

  [CLEAR_SEARCH]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

export default search;
