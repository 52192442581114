export const CLEAR_MODULE = 'module/CLEAR_MODULE';
export const GET_MODULE = 'module/GET_MODULE';
export const PUT_MODULE = 'module/PUT_MODULE';

export const GET_MODULE_ARTICLES = 'module/GET_MODULE_ARTICLES';
export const CLEAR_MODULE_ARTICLES = 'module/CLEAR_MODULE_ARTICLES';

export const GET_MODULE_CHAPTERS = 'module/GET_MODULE_CHAPTERS';
export const CLEAR_MODULE_CHAPTERS = 'module/CLEAR_MODULE_CHAPTERS';

export const GET_MODULE_VIDEOS = 'moduleVideos/GET_MODULE_VIDEOS';
export const CLEAR_MODULE_VIDEOS = 'moduleVideos/CLEAR_MODULE_VIDEOS';
