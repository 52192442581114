import jwtDecode from 'jwt-decode';

import {
  createReducer,
  createRequestReducer
} from '../utils';
import {
  SET_TOKEN,
  DELETE_TOKEN,
  ADD_DEFERRED_ACTION,
  CLEAR_DEFERRED_ACTIONS,
  POST_LOGIN,
  POST_REFRESH_TOKEN
} from './constants';

const getTokenData = (token) => {
  let data = null;

  try {
    data = jwtDecode(token);
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }

  return data;
};

const initialState = {
  token: null,
  refreshToken: null,
  tokenData: null,
  isAuthorizing: false,
  isAuthorized: false,
  isRefreshing: false,
  deferredActions: [],
  errors: null
};

const auth = createReducer(initialState, {
  [SET_TOKEN]: (state, { token, refreshToken }) => {
    const tokenData = getTokenData(token);

    return {
      ...state,
      token,
      refreshToken,
      tokenData,
      isAuthorized: true
    };
  },

  [DELETE_TOKEN]: (state) => ({
    ...state,
    token: null,
    refreshToken: null,
    tokenData: null,
    isAuthorized: false,
    isRefreshing: false
  }),

  [ADD_DEFERRED_ACTION]: (state, { action }) => ({
    ...state,
    deferredActions: [
      ...state.deferredActions,
      action
    ]
  }),

  [CLEAR_DEFERRED_ACTIONS]: (state) => ({
    ...state,
    deferredActions: []
  }),

  [POST_LOGIN]: (state, action) => createRequestReducer(state, action, {
    SEND: () => ({
      ...state,
      isAuthorizing: true
    }),

    SUCCESS: () => {
      const { access: token, refresh: refreshToken } = action.request.response.data;
      const tokenData = getTokenData(token);

      return {
        ...state,
        token,
        refreshToken,
        tokenData,
        isAuthorizing: false,
        isAuthorized: true,
        errors: null
      };
    },

    FAIL: () => ({
      ...state,
      isAuthorizing: false,
      errors: action.request.response.data
    })
  }),

  [POST_REFRESH_TOKEN]: (state, action) => createRequestReducer(state, action, {
    SEND: () => ({
      ...state,
      isRefreshing: true
    }),

    SUCCESS: () => {
      const { access: token, refresh: refreshToken } = action.request.response.data;
      const tokenData = getTokenData(token);

      return {
        ...state,
        token,
        refreshToken,
        tokenData,
        isRefreshing: false,
        isAuthorized: true
      };
    },

    FAIL: () => ({
      ...state,
      isRefreshing: false,
      errors: action.request.response.data
    })
  })
});

export default auth;
