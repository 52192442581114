import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import { POST_LOGOUT_TOKEN } from '../auth/constants';
import { CLEAR_CHAPTERS, GET_CHAPTERS } from './constants';

const chapters = createReducer(defaultFetchDataState, {
  [GET_CHAPTERS]: defaultFetchReducer,

  [CLEAR_CHAPTERS]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

export default chapters;
