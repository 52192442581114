import { createSelector } from 'reselect';

const slice = ({ app }) => app;

export const cookieConsentSelector = createSelector(
  slice,
  ({ cookieConsent }) => cookieConsent
);

export const i18nSelector = createSelector(
  slice,
  ({ i18n }) => i18n || {}
);

export const i18nInitializedSelector = createSelector(
  i18nSelector,
  ({ initialized }) => initialized || false
);

export const langSelector = createSelector(
  i18nSelector,
  ({ lang }) => lang || null
);

export const linksVisibleSelector = createSelector(
  slice,
  ({ linksVisible }) => linksVisible
);

export const sidebarSelector = createSelector(
  slice,
  ({ sidebar }) => sidebar
);
