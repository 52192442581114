import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';

import { PUT_ACCOUNT_PASSWORD } from './constants';

const initialState = {
  ...defaultFetchDataState,
  updated: false
};

const updatePassword = createReducer(initialState, {
  [PUT_ACCOUNT_PASSWORD]: (state, action) => defaultFetchReducer(state, action, {
    SEND: () => ({
      ...state,
      fetching: true,
      updated: false
    }),

    SUCCESS: () => ({
      ...initialState,
      errors: null,
      fetching: false,
      updated: true
    })
  })
});

export default updatePassword;
