import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import {
  POST_BOOK_TRANSLATION,
  CLEAR_BOOK_TRANSLATION
} from './constants';

const initialState = {
  ...defaultFetchDataState,
  saved: false
};

const bookTranslation = createReducer(initialState, {
  [POST_BOOK_TRANSLATION]: (state, action) => defaultFetchReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: action.request.response.data,
      errors: null,
      saved: true
    })
  }),

  [CLEAR_BOOK_TRANSLATION]: defaultResetStateReducer(initialState)
});

export default bookTranslation;
