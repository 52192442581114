import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import {
  CLEAR_CONTRIBUTORS,
  GET_CONTRIBUTORS
} from './constants';

const initialState = {
  ...defaultFetchDataState
};

const contributors = createReducer(initialState, {
  [GET_CONTRIBUTORS]: defaultFetchReducer,

  [CLEAR_CONTRIBUTORS]: defaultResetStateReducer(initialState)
});

export default contributors;
