import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import config from '../config';

export const lngs = config.LANGUAGE_CODES;

const options = {
  debug: false,
  supportedLngs: lngs,
  defaultNS: 'default',
  returnEmptyString: false,
  fallbackLng: 'en',
  load: 'languageOnly',
  cleanCode: true,

  ns: [], // reset default ns
  nsSeparator: false,

  interpolation: {
    escapeValue: false
  },

  detection: {
    order: ['path', 'navigator'],
    caches: []
  },

  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json'
  }
};

const i18n = i18next.createInstance(options);
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options);

export const getCurrentLang = () => i18n.language.substring(0, 2) || lngs[0];

export const pathI18n = (to) => {
  const lang = getCurrentLang();

  if (typeof to === 'string') {
    return `/${lang}${to}`;
  }

  if (typeof to === 'object') {
    return {
      ...to,
      pathname: `/${lang}${to.pathname}`
    };
  }

  return to;
};

export default i18n;
