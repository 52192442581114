import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import { POST_LOGOUT_TOKEN } from '../auth/constants';
import { CLEAR_SECTIONS, GET_SECTIONS } from './constants';

const sections = createReducer(defaultFetchDataState, {
  [GET_SECTIONS]: defaultFetchReducer,

  [CLEAR_SECTIONS]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

export default sections;
