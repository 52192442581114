import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import { POST_LOGOUT_TOKEN } from '../auth/constants';
import { CLEAR_POSTERS, GET_POSTERS } from './constants';

const posters = createReducer(defaultFetchDataState, {
  [GET_POSTERS]: defaultFetchReducer,

  [CLEAR_POSTERS]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

export default posters;
