import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import { POST_LOGOUT_TOKEN } from '../auth/constants';
import { GET_EVENTS } from './constants';

const initialState = {
  ...defaultFetchDataState
};

const events = createReducer(initialState, {
  [GET_EVENTS]: defaultFetchReducer,

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(initialState)
});

export default events;
