import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import { POST_LOGOUT_TOKEN } from '../auth/constants';
import { CLEAR_MARKUP, GET_MARKUP } from './constants';

const markup = createReducer(defaultFetchDataState, {
  [GET_MARKUP]: defaultFetchReducer,

  [CLEAR_MARKUP]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

export default markup;
