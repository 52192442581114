import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import { POST_LOGOUT_TOKEN } from '../auth/constants';
import { CLEAR_CASE_STUDIES, GET_CASE_STUDIES } from './constants';

const caseStudies = createReducer(defaultFetchDataState, {
  [GET_CASE_STUDIES]: defaultFetchReducer,

  [CLEAR_CASE_STUDIES]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

export default caseStudies;
