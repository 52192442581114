import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import {
  POST_LOGOUT_TOKEN
} from '../auth/constants';
import {
  DELETE_INVITE,
  GET_INVITE,
  GET_INVITE_CURRENT,
  POST_INVITE,
  POST_INVITE_ACCEPT
} from './constants';

const initialState = {
  ...defaultFetchDataState
};

const invite = createReducer(initialState, {
  [DELETE_INVITE]: defaultFetchReducer,

  [GET_INVITE]: defaultFetchReducer,

  [GET_INVITE_CURRENT]: defaultFetchReducer,

  [POST_INVITE]: defaultFetchReducer,

  [POST_INVITE_ACCEPT]: defaultFetchReducer,

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(initialState)
});

export default invite;
