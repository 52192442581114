import { combineReducers } from 'redux';

import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import {
  POST_LOGOUT_TOKEN
} from '../auth/constants';
import {
  GET_MODULE,
  PUT_MODULE,
  CLEAR_MODULE,
  GET_MODULE_ARTICLES,
  CLEAR_MODULE_ARTICLES,
  GET_MODULE_CHAPTERS,
  CLEAR_MODULE_CHAPTERS,
  GET_MODULE_VIDEOS,
  CLEAR_MODULE_VIDEOS
} from './constants';

const initialState = {
  ...defaultFetchDataState,
  updating: false
};

const module = createReducer(initialState, {
  [GET_MODULE]: (state, action) => defaultFetchReducer(state, action, {
    FAIL: () => ({
      ...state,
      fetching: false,
      errors: action.request.response.data.error
    })
  }),

  [PUT_MODULE]: (state, action) => defaultFetchReducer(state, action, {
    SEND: () => ({
      ...state,
      updating: true
    }),

    SUCCESS: () => ({
      ...state,
      updating: false,
      data: action.request.response.data,
      errors: null
    }),

    FAIL: () => ({
      ...state,
      updating: false,
      errors: action.request.response.data
    })
  }),

  [CLEAR_MODULE]: (state) => ({
    ...state,
    ...defaultFetchDataState
  })
});

const articles = createReducer(defaultFetchDataState, {
  [GET_MODULE_ARTICLES]: defaultFetchReducer,

  [CLEAR_MODULE_ARTICLES]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

const chapters = createReducer(defaultFetchDataState, {
  [GET_MODULE_CHAPTERS]: defaultFetchReducer,

  [CLEAR_MODULE_CHAPTERS]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

const videos = createReducer(defaultFetchDataState, {
  [GET_MODULE_VIDEOS]: defaultFetchReducer,

  [CLEAR_MODULE_VIDEOS]: defaultResetStateReducer((defaultFetchDataState)),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

export default combineReducers({
  module,
  articles,
  chapters,
  videos
});
