import merge from 'deepmerge';

const defaultConfig = {
  APP_PROTOCOL: '',
  APP_HOST: '',
  APP_PATH: '/',

  API_PROTOCOL: '',
  API_HOST: '',
  API_PATH: '/api/',

  KMM_URL: '',
  F4P_ENABLE: '',

  GTAG_ID: '',
  GTM_ID: '',
  ADW_ID: '',
  SENTRY_DSN: '',

  STRIPE_API_KEY: '',
  STRIPE_DISABLE: '',

  PRICE_BASIC_MONTH: '',
  PRICE_BASIC_MONTH_DISCOUNT: '',
  PRICE_BASIC_YEAR: '',
  PRICE_BASIC_YEAR_DISCOUNT: '',

  PRICE_ADVANCED_MONTH: '',
  PRICE_ADVANCED_MONTH_DISCOUNT: '',
  PRICE_ADVANCED_YEAR: '',
  PRICE_ADVANCED_YEAR_DISCOUNT: '',

  SEARCH_ENABLED: '',
  QUIZ_ENABLED: '',
  SUGGEST_TRANSLATION_ENABLED: '',
  CORPORATE_ACCOUNTS_ENABLED: '',
  CONTRIBUTORS_ENABLED: '',
  RESOURCES_TEMPLATES_ENABLED: '',
  COUPON_CODES_ENABLED: '',

  BLOG_URL: '',

  LANGUAGE_CODES: ['en'],
  LANGUAGE_SWITCH: '',

  SUPPORT_EMAIL: 'privacy-mge@mauvius.com',
  MAX_FILE_SIZE: 15 * (2 ** 20) // 15MB
};

let runtimeConfig = {};

/* eslint-disable no-underscore-dangle */
if (typeof window !== 'undefined' && window.__config && typeof window.__config === 'object') {
  runtimeConfig = window.__config;
}

const overwriteArrayMerge = (targetArray, sourceArray) => sourceArray;

const config = merge(defaultConfig, runtimeConfig, { arrayMerge: overwriteArrayMerge });

export default config;
