import {
  createReducer,
  createRequestReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import {
  POST_LOGOUT_TOKEN
} from '../auth/constants';
import {
  DELETE_SOCIAL_MEDIA,
  POST_SOCIAL_MEDIA
} from '../socialMedia/constants';
import {
  GET_ACCOUNT,
  PUT_ACCOUNT,
  PUT_ACCOUNT_PHOTO
} from './constants';

const initialState = {
  ...defaultFetchDataState,
  updated: false
};

const account = createReducer(initialState, {
  [GET_ACCOUNT]: defaultFetchReducer,

  [PUT_ACCOUNT]: (state, action) => defaultFetchReducer(state, action, {
    SEND: () => ({
      ...state,
      fetching: true,
      updated: false
    }),

    SUCCESS: () => ({
      ...state,
      data: action.request.response.data,
      errors: null,
      fetching: false,
      updated: true
    })
  }),

  [PUT_ACCOUNT_PHOTO]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        ...action.request.response.data
      }
    })
  }),

  [POST_SOCIAL_MEDIA]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        socialMedia: [
          ...state.data.socialMedia,
          action.request.response.data
        ]
      }
    })
  }),

  [DELETE_SOCIAL_MEDIA]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        socialMedia: state.data.socialMedia.filter(({ id }) => id !== action.id)
      }
    })
  }),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(initialState)
});

export default account;
