import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import { POST_LOGOUT_TOKEN } from '../auth/constants';
import {
  CLEAR_VAT_ERRORS,
  GET_VAT,
  POST_VAT,
  RESET_VAT
} from './constants';
import i18n from '../../i18n';

const initialState = {
  ...defaultFetchDataState,
  vatId: null,
  vatAccepted: false,
  vatValidated: false
};

/**
 * Stripe validation status response
 * @param {Object} verification
 * @param {"pending" | "verified" | "unverified" | "unavailable"} verification.status
 */

const vat = createReducer(initialState, {
  [GET_VAT]: defaultFetchReducer,

  [POST_VAT]: (state, action) => defaultFetchReducer(state, action, {
    SEND: () => ({
      ...state,
      vatId: action.vatId,
      fetching: true,
      vatAccepted: false
    }),

    SUCCESS: () => ({
      ...state,
      fetching: false,
      data: action.request.response.data,
      errors: null,
      vatAccepted: true
    }),

    FAIL: () => {
      let errors = null;
      const { data } = action.request.response;

      if (data) {
        errors = {
          vatId: [i18n.t('Form_Vat_error')]
        };
      }

      return {
        ...state,
        fetching: false,
        errors
      };
    }
  }),

  [RESET_VAT]: defaultResetStateReducer(initialState),

  [CLEAR_VAT_ERRORS]: (state) => ({
    ...state,
    errors: null
  }),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(initialState)
});

export default vat;
