import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import {
  POST_CASE_STUDY_TRANSLATION,
  CLEAR_CASE_STUDY_TRANSLATION
} from './constants';

const initialState = {
  ...defaultFetchDataState,
  saved: false
};

const caseStudyTranslation = createReducer(initialState, {
  [POST_CASE_STUDY_TRANSLATION]: (state, action) => defaultFetchReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: action.request.response.data,
      errors: null,
      saved: true
    })
  }),

  [CLEAR_CASE_STUDY_TRANSLATION]: defaultResetStateReducer(initialState)
});

export default caseStudyTranslation;
