import config from '../../config';
import { createReducer } from '../utils';
import { SET_EDITOR_LANG } from './constants';

const labels = {
  en: 'English',
  de: 'Deutsch',
  pt: 'Português',
  es: 'Español',
  pl: 'Polski'
};

const initialState = {
  lang: 'en',
  options: config.LANGUAGE_CODES.map((c) => ({
    label: labels[c],
    value: c,
    icon: `/flags/${c}.png`
  }))
};

const editor = createReducer(initialState, {
  [SET_EDITOR_LANG]: (state, { lang }) => ({
    ...state,
    lang
  })
});

export default editor;
