import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';

import {
  POST_RESET_PASSWORD,
  PUT_RESET_PASSWORD_RESTORE
} from './constants';

const resetPassword = createReducer(defaultFetchDataState, {
  [POST_RESET_PASSWORD]: defaultFetchReducer,

  [PUT_RESET_PASSWORD_RESTORE]: defaultFetchReducer
});

export default resetPassword;
