import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import { CLEAR_PLANS, GET_PLANS } from './constants';

const plans = createReducer(defaultFetchDataState, {
  [GET_PLANS]: defaultFetchReducer,

  [CLEAR_PLANS]: defaultResetStateReducer(defaultFetchDataState)
});

export default plans;
