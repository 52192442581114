import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';

import {
  GET_BOOK,
  CLEAR_BOOK,
  PUT_BOOK
} from './constants';

const initialState = {
  ...defaultFetchDataState,
  updating: false
};

const book = createReducer(initialState, {
  [GET_BOOK]: (state, action) => defaultFetchReducer(state, action, {
    FAIL: () => ({
      ...state,
      fetching: false,
      errors: action.request.response.data.error
    })
  }),

  [PUT_BOOK]: (state, action) => defaultFetchReducer(state, action, {
    SEND: () => ({
      ...state,
      updating: true
    }),

    SUCCESS: () => ({
      ...state,
      updating: false,
      data: action.request.response.data,
      errors: null
    }),

    FAIL: () => ({
      ...state,
      updating: false,
      errors: action.request.response.data
    })
  }),

  [CLEAR_BOOK]: (state) => ({
    ...state,
    ...defaultFetchDataState
  })
});

export default book;
