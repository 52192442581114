import validate from 'validate.js';

validate.options = {
  fullMessages: false
};

// required field message
validate.validators.presence.options = {
  allowEmpty: false,
  message: 'Required field'
};

// email field message
validate.validators.email.options = {
  message: 'Invalid email'
};

// checked field message
validate.validators.inclusion.options = {
  within: [true],
  message: 'Required field'
};
