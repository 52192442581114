import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap']
  },
  custom: {
    families: ['Antipol Extended'],
    urls: [`${process.env.PUBLIC_URL}/fonts/Antipol-Extended.css`]
  }
});
