import { matchPath } from 'react-router';

import { pathI18n } from '../i18n';

export const APP = {
  kmm: 'kmm',
  f4p: 'f4p',
  editor: 'editor'
};

export const ACCOUNT_TYPE = {
  person: 'person',
  organization: 'organization'
};

export const PAYMENT_TYPE = {
  month: 'month',
  year: 'year'
};

export const SUBSCRIPTION_TYPE = {
  free: 'free',
  book: 'book',
  modules: 'modules'
};

export const SUBSCRIPTION_STATUS = {
  new: 'new',
  active: 'active',
  cancellation: 'cancellation', //
  cancelled: 'cancelled', //
  past_due: 'past_due', //
  unpaid: 'unpaid', //
  incomplete: 'incomplete',
  incomplete_expired: 'incomplete_expired',
  trialing: 'trialing'
};

export const getNavigationLinks = (t) => [
  {
    to: `/book/${APP.kmm}/`,
    label: t('navigation_views'),
    isActive: (m, l) => matchPath(l.pathname, { path: pathI18n('/book/') })
  },
  {
    to: `/chapters/${APP.kmm}/`,
    label: t('navigation_chapters'),
    isActive: (m, l) => matchPath(l.pathname, { path: pathI18n('/chapters/') })
  },
  {
    to: `/case-studies/${APP.kmm}/`,
    label: t('navigation_case_studies'),
    isActive: (m, l) => matchPath(l.pathname, { path: pathI18n('/case-studies/') })
  },
  {
    to: `/resources/${APP.kmm}/`,
    label: t('navigation_resources'),
    isActive: (m, l) => matchPath(l.pathname, { path: pathI18n('/resources/') })
  },
  {
    to: `/modules/${APP.kmm}/`,
    label: t('navigation_modules'),
    isActive: (m, l) => matchPath(l.pathname, { path: pathI18n('/modules/') })
  }
];

export const getRegistrationLinks = (t) => ([
  {
    to: '/registration/',
    label: t('Registration_personal'),
    exact: true
  },
  {
    to: '/registration/organization/',
    label: t('Registration_organization'),
    exact: true
  }
]);
