import { createReducer } from '../utils';
import {
  SET_I18N,
  TOGGLE_LINKS_VISIBLE,
  OPEN_NAVIGATION_SIDEBAR,
  CLOSE_NAVIGATION_SIDEBAR,
  TOGGLE_NAVIGATION_SIDEBAR,
  SET_NAVIGATION_SIDEBAR,
  SET_COOKIE_CONSENT
} from './constants';

const initialState = {
  cookieConsent: false,
  i18n: {
    initialized: false,
    lang: null
  },
  linksVisible: false,
  sidebar: false
};

const app = createReducer(initialState, {
  [SET_COOKIE_CONSENT]: (state) => ({
    ...state,
    cookieConsent: true
  }),

  [SET_I18N]: (state, { i18n }) => ({
    ...state,
    i18n: {
      ...state.i18n,
      ...i18n
    }
  }),

  [TOGGLE_LINKS_VISIBLE]: (state) => ({
    ...state,
    linksVisible: !state.linksVisible
  }),

  [OPEN_NAVIGATION_SIDEBAR]: (state) => ({
    ...state,
    sidebar: true
  }),

  [CLOSE_NAVIGATION_SIDEBAR]: (state) => ({
    ...state,
    sidebar: false
  }),

  [TOGGLE_NAVIGATION_SIDEBAR]: (state) => ({
    ...state,
    sidebar: !state.sidebar
  }),

  [SET_NAVIGATION_SIDEBAR]: (state, { open }) => ({
    ...state,
    sidebar: !!open
  })
});

export default app;
