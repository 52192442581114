/* eslint-disable prefer-destructuring,no-unused-vars */
import storageFactory from './storageFactory';

export const localStorage = storageFactory('localStorage');

export const noop = () => Promise.resolve({});

export const posterLinkFromPoster = ({ slug }, app) => {
  const parts = ['book', app, encodeURIComponent(slug || '')];
  return `/${parts.filter(Boolean).join('/')}/`;
};

export const bookLinkFromPoster = ({ slug, defaultSection }, app) => {
  const parts = ['book', app, encodeURIComponent(slug)];

  if (defaultSection) {
    parts.push(encodeURIComponent(defaultSection.number));
  }

  return `/${parts.filter(Boolean).join('/')}/`;
};

export const bookLinkFromSection = ({ poster, number }, app, query = '') => {
  const parts = ['book', app, encodeURIComponent(poster.slug || ''), encodeURIComponent(number || '')];
  return `/${parts.filter(Boolean).join('/')}/${query}`;
};

export const bookLinkFromMarkupSection = ({ slug, number }, app) => {
  const parts = ['book', app, encodeURIComponent(slug || ''), encodeURIComponent(number || '')];
  return `/${parts.filter(Boolean).join('/')}/`;
};

export const caseStudyLinkFromCaseStudy = ({ slug }, app) => {
  const parts = ['case-studies', app, encodeURIComponent(slug || '')];
  return `/${parts.filter(Boolean).join('/')}/`;
};

export const chapterLinkFromChapter = ({ slug }, app) => {
  const parts = ['chapters', app, encodeURIComponent(slug || '')];
  return `/${parts.filter(Boolean).join('/')}/`;
};

export const moduleLinkFromModule = ({ slug }, app) => {
  const parts = ['modules', app, encodeURIComponent(slug || '')];
  return `/${parts.filter(Boolean).join('/')}/`;
};

export const videoCategoryLinkFromCategory = ({ slug }, app) => {
  const parts = ['resources', app, 'videos', encodeURIComponent(slug)];
  return `/${parts.filter(Boolean).join('/')}/`;
};

export const formatPrice = (float, digits = 1) => (
  `${float.toFixed(digits).replace('.', ',')}€`
);

export const isNumber = (num) => (typeof num === 'number') && !Number.isNaN(num) && !Number.isFinite(num);

export const isNotEmptyArray = (obj) => Array.isArray(obj) && obj.length > 0;

export const createArray = (length, iterator = () => null) => Array.from({ length }, iterator);

export const scrollToElementById = (id, offset = 0, options = {}) => {
  const element = document.getElementById(id);

  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: window.scrollY + offsetPosition,
      behavior: 'smooth',
      ...options
    });
  }
};

export const getScrollbarWidth = () => {
  const scrollDiv = document.createElement('div');
  scrollDiv.style.cssText = 'width:100px;height:100px;overflow:scroll;position:absolute;top: -9999px';
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

export const getEmbedUrl = (url, nocookie = false) => {
  if (url.indexOf('youtu') > -1) {
    const id = new URL(url).searchParams.get('v');
    const domain = nocookie ? 'www.youtube-nocookie.com' : 'www.youtube.com';
    return `https://${domain}/embed/${id}`;
  }

  if (url.indexOf('vimeo') > -1) {
    let location = 'https://player.vimeo.com/video/';
    const queryParams = [];

    const regexPrivate = /\/(\d+)\/(\w+)$/;
    const regexPublic = /\/(\d+)$/;

    if (regexPrivate.test(url)) {
      const match = regexPrivate.exec(url);

      if (match) {
        location += match[1];
        queryParams.push(`h=${match[2]}`);
      }
    } else if (regexPublic.test(url)) {
      const match = regexPublic.exec(url);

      if (match) {
        location += match[1];
      }
    }

    if (nocookie) {
      queryParams.push('dnt=1');
    }

    return `${location}?${queryParams.join('&')}`;
  }

  return url;
};
