import { createReducer } from '../utils';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './constants';

const initialState = [];

const notifications = createReducer(initialState, {
  [ADD_NOTIFICATION]: (state, { data }) => {
    const lastId = Math.max(-1, ...state.map(({ id }) => id));

    return [
      ...state,
      {
        ...data,
        id: lastId + 1
      }
    ];
  },

  [REMOVE_NOTIFICATION]: (state, { id }) => state.filter((item) => item.id !== id)
});

export default notifications;
