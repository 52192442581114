import {
  createReducer,
  createRequestReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';
import {
  CLEAR_ERRORS,
  GET_SUBSCRIPTION,
  POST_CANCEL_SUBSCRIPTION,
  POST_SUBSCRIPTION,
  POST_SUBSCRIPTION_COUPON_CODE
} from './constants';
import { POST_LOGOUT_TOKEN } from '../auth/constants';

const initialState = {
  ...defaultFetchDataState,
  canceling: false,
  creating: false,
  paymentCompleted: false,
  paymentDeclined: false
};

const subscription = createReducer(initialState, {
  [GET_SUBSCRIPTION]: (state, action) => defaultFetchReducer(state, action, {
    SUCCESS: () => {
      const { data } = action.request.response;

      let {
        paymentCompleted,
        paymentDeclined
      } = state;

      if (data && data.isActive) {
        if (paymentCompleted) {
          paymentCompleted = false;
        }

        if (paymentDeclined) {
          paymentDeclined = false;
        }
      }

      return {
        ...state,
        fetching: false,
        data,
        errors: null,
        paymentCompleted,
        paymentDeclined
      };
    }
  }),

  [POST_CANCEL_SUBSCRIPTION]: (state, action) => defaultFetchReducer(state, action, {
    SEND: () => ({
      ...state,
      canceling: true,
      errors: null
    }),

    SUCCESS: () => ({
      ...state,
      data: action.request.response.data,
      canceling: false
    }),

    FAIL: () => ({
      ...state,
      canceling: false,
      errors: action.request.response.data
    })
  }),

  [POST_SUBSCRIPTION]: (state, action) => createRequestReducer(state, action, {
    SEND: () => ({
      ...state,
      creating: true,
      errors: null
    }),

    SUCCESS: () => ({
      ...state,
      data: action.request.response.data,
      creating: false
    }),

    FAIL: () => ({
      ...state,
      creating: false,
      errors: action.request.response.data
    })
  }),

  [POST_SUBSCRIPTION_COUPON_CODE]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: action.request.response.data
    })
  }),

  [CLEAR_ERRORS]: (state) => ({
    ...state,
    errors: null
  }),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(initialState)
});

export default subscription;
