import { combineReducers } from 'redux';

import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import { POST_LOGOUT_TOKEN } from '../auth/constants';
import {
  GET_RESOURCES_FILES,
  CLEAR_RESOURCES_FILES,
  GET_TEMPLATES,
  CLEAR_TEMPLATES,
  GET_VIDEO_CATEGORIES,
  CLEAR_VIDEO_CATEGORIES,
  GET_VIDEO_FILES,
  CLEAR_VIDEO_FILES
} from './constants';

const resourceFiles = createReducer(defaultFetchDataState, {
  [GET_RESOURCES_FILES]: defaultFetchReducer,

  [CLEAR_RESOURCES_FILES]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

const videoFiles = createReducer(defaultFetchDataState, {
  [GET_VIDEO_FILES]: defaultFetchReducer,

  [CLEAR_VIDEO_FILES]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

const videoCategories = createReducer(defaultFetchDataState, {
  [GET_VIDEO_CATEGORIES]: defaultFetchReducer,

  [CLEAR_VIDEO_CATEGORIES]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

const templates = createReducer(defaultFetchDataState, {
  [GET_TEMPLATES]: defaultFetchReducer,

  [CLEAR_TEMPLATES]: defaultResetStateReducer(defaultFetchDataState),

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

export default combineReducers({
  resourceFiles,
  videoFiles,
  videoCategories,
  templates
});
