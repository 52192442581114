import {
  createReducer,
  createRequestReducer
} from '../utils';

import {
  POST_REGISTER,
  POST_CONFIRM_EMAIL
} from './constants';

const initialState = {
  invite: null,
  registering: false,
  confirming: false,
  errors: null
};

const registration = createReducer(initialState, {
  [POST_REGISTER]: (state, action) => createRequestReducer(state, action, {
    SEND: () => ({
      ...state,
      registering: true
    }),

    SUCCESS: () => ({
      ...state,
      registering: false,
      errors: null
    }),

    FAIL: () => ({
      ...state,
      registering: false,
      errors: action.request.response.data
    })
  }),

  [POST_CONFIRM_EMAIL]: (state, action) => createRequestReducer(state, action, {
    SEND: () => ({
      ...state,
      confirming: true
    }),

    SUCCESS: () => ({
      ...state,
      confirming: false,
      errors: null
    }),

    FAIL: () => ({
      ...state,
      confirming: false,
      errors: action.request.response.data
    })
  })
});

export default registration;
