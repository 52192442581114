import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  defaultResetStateReducer
} from '../utils';

import {
  POST_LOGOUT_TOKEN
} from '../auth/constants';
import {
  POST_SOCIAL_MEDIA,
  PATCH_SOCIAL_MEDIA,
  PUT_SOCIAL_MEDIA,
  DELETE_SOCIAL_MEDIA
} from './constants';

const socialMedia = createReducer(defaultFetchDataState, {
  [POST_SOCIAL_MEDIA]: defaultFetchReducer,

  [PATCH_SOCIAL_MEDIA]: defaultFetchReducer,

  [PUT_SOCIAL_MEDIA]: defaultFetchReducer,

  [DELETE_SOCIAL_MEDIA]: defaultFetchReducer,

  [POST_LOGOUT_TOKEN]: defaultResetStateReducer(defaultFetchDataState)
});

export default socialMedia;
