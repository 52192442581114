import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import { setConfiguration } from 'react-grid-system';
import * as Sentry from '@sentry/browser';
import Modal from 'react-modal';

import config from './config';
import { APP } from './constants';
import i18n, { getCurrentLang } from './i18n';

import history from './clientHistory';
import configureStore from './redux';
import { i18nInitializedSelector } from './redux/app/selectors';
import { checkCookieConsent, setI18n } from './redux/app/actions';
import { checkToken } from './redux/auth/actions';

import './loadFonts';
import './dayjsConfig';
import './validation';

Modal.setAppElement('#modal');

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    blacklistUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extensions:\/\//i
    ]
  });
}

setConfiguration({
  breakpoints: [576, 768, 1000, 1300],
  containerWidths: [570, 760, 980, 1260],
  gutterWidth: 30
});

const store = configureStore(history, window.__data); // eslint-disable-line no-underscore-dangle

i18n.on('initialized', () => {
  const lang = getCurrentLang();
  store.dispatch(setI18n({ initialized: true, lang }));
});

i18n.on('languageChanged', () => {
  const initialized = i18nInitializedSelector(store.getState());

  if (!initialized) {
    return;
  }

  const lang = getCurrentLang();
  store.dispatch(setI18n({ lang }));
});

store.dispatch(checkToken());
store.dispatch(checkCookieConsent());

const importBuildTarget = () => {
  if (process.env.REACT_APP_TARGET === APP.kmm) {
    return import('./app-kmm');
  }

  if (process.env.REACT_APP_TARGET === APP.f4p) {
    return import('./app-f4p');
  }

  if (process.env.REACT_APP_TARGET === APP.editor) {
    return import('./app-editor');
  }

  return Promise.reject(new Error(`No such build target: ${process.env.REACT_APP_TARGET}`));
};

importBuildTarget().then(({ default: App }) => {
  render(<App store={store} history={history} />, document.getElementById('root'));
});
